<template>
  <v-row no-gutters>
    <v-card class="ma-5" elevation="0">
      <v-row justify="space-between">
        <v-col cols="auto">
          <v-breadcrumbs
            :items="itemsbreadcrumbs"
            divider="-"
            large
          ></v-breadcrumbs>
        </v-col>
        <v-col cols="auto">
          <div class="red--text">
            Warning: Se sugiere a todos los aplicantes programar cita medica 2
            semanas antes de su cita en embajada.
          </div>
        </v-col>
      </v-row>

      <v-card-title class="py-0 mb-3">
        <v-spacer></v-spacer>
        <v-icon right @click="startTour()">
          fa-solid fa-circle-question
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-row>
            <!-- Tipo de visas -->
            <v-col cols="12" md="4" class="pb-0" v-if="true">
              <h2>Tipo de visa</h2>
              <v-autocomplete
                v-model="tipo_visa"
                :items="items_tipo_visas"
                item-text="nombre"
                item-value="id_tipo_visa"
                :hint="desc_visa"
                persistent-hint
                outlined
                dense
                return-object
                class="mt-3"
                @change="seleccionTipoVisa()"
              >
                <template v-slot:item="data">
                  {{ data.item.nombre }}
                </template>
              </v-autocomplete>
            </v-col>
            <!-- Informacion importante -->
            <v-col cols="12">
              <v-card elevation="0">
                <v-card-text class="py-0 px-0 text-justify subtitle-1">
                  <v-row>
                    <v-col
                      cols="auto"
                      class="py-0 pr-0"
                      align-self="center"
                      v-if="true"
                    >
                    Es necesario que cree un perfil y registre su cita de inmigrante en el siguiente sitio web:
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-0 pb-0"
                      align-self="center"
                      v-if="true"
                    >
                      <strong>
                        <a
                          href="https://ais.usvisa-info.com/es-sv/iv"
                          target="_blank"
                          >ais.usvisa-info.com</a
                        >
                      </strong>
                    </v-col>
                    <v-col cols="auto" class="" align-self="center" v-if="false">
                      <v-checkbox
                        v-model="checkboxUID"
                        label="Si"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      v-if="checkboxUID && true"
                      class="py-1"
                      align-self="center"
                    >
                      <div>UID:</div>
                      <v-otp-input
                        v-model="uid"
                        length="8"
                        type="number"
                        :disabled="checkbox_p92"
                      ></v-otp-input>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="true">
                      Si su visa es aprobada y usted no ha completado su perfil
                      UID, su pasaporte será retenido rechazando su proceso.
                    </v-col>
                    <v-col cols="auto" align-self="center">
                      <v-checkbox
                        v-if="esta_logueado"
                        v-model="cita_vencida"
                        label="Cita vencida"
                        dense
                        hide-details
                        class="pa-0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Formulario aplicante -->
            <v-col cols="12" md="6">
              <v-col cols="7" md="4" class="mx-auto py-0">
                <div>Número de personas</div>
                <v-text-field
                  v-model="num_personas"
                  outlined
                  dense
                  type="number"
                  :rules="rules_numero"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-1">
                <div class="font-weight-bold">DATOS APLICANTE</div>
              </v-col>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pr-1' : 'px-0'"
                >
                  <v-text-field
                    v-model="nombre"
                    label="Nombre"
                    outlined
                    dense
                    :rules="rules"
                    :disabled="validarCampo()"
                    maxlength="99"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pl-1' : 'px-0'"
                >
                  <v-text-field
                    v-model="apellido"
                    label="Apellido"
                    outlined
                    dense
                    :rules="rules"
                    :disabled="validarCampo()"
                    maxlength="99"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pr-1' : 'px-0'"
                >
                  <v-text-field
                    v-model="pasaporte"
                    label="Pasaporte"
                    outlined
                    dense
                    :rules="rules"
                    :disabled="validarCampo()"
                    maxlength="49"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pl-1' : 'px-0'"
                >
                  <v-text-field
                    v-model="n_caso"
                    label="Número de caso"
                    outlined
                    dense
                    :rules="rules"
                    :disabled="validarCampo()"
                    maxlength="49"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pr-1' : 'px-0'"
                >
                  <vue-tel-input-vuetify
                    v-model="telefono_n1"
                    defaultCountry="US"
                    :ignoredCountries="ignoreCount"
                    outlined
                    dense
                    label="Teléfono 1"
                    append-icon="fa solid fa-caret-down"
                    :success-messages="success_telefono_1"
                    :error-messages="error_telefono_1"
                    @input="validarTelefono1"
                    mode="international"
                    :disabled="validarCampo()"
                    :rules="rules"
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pl-1' : 'px-0'"
                >
                  <vue-tel-input-vuetify
                    v-model="telefono_n2"
                    :onlyCountries="onlyCount"
                    outlined
                    dense
                    label="Teléfono 2"
                    append-icon="fa solid fa-caret-down"
                    :success-messages="success_telefono_2"
                    :error-messages="error_telefono_2"
                    @input="validarTelefono2"
                    mode="international"
                    :disabled="validarCampo()"
                    :rules="rules"
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pr-1' : 'px-0'"
                >
                  <vue-tel-input-vuetify
                    v-model="telefono_n3"
                    outlined
                    dense
                    label="Teléfono 3 (opcional)"
                    append-icon="fa solid fa-caret-down"
                    mode="international"
                    :disabled="validarCampo()"
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndUp ? 'pl-1' : 'px-0'"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fecha_nacimiento"
                        label="Fecha de nacimiento"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules"
                        :disabled="validarCampo()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_nacimiento"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1900-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="direccion"
                    label="Dirección"
                    outlined
                    dense
                    auto-grow
                    row-height="1"
                    :rules="rules"
                    :disabled="validarCampo()"
                    maxlength="199"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="correo"
                    label="Correo"
                    outlined
                    dense
                    auto-grow
                    row-height="1"
                    :rules="requeridoCorreo"
                    :disabled="validarCampo()"
                    maxlength="99"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    v-if="bandera == 0"
                    color="primary"
                    @click="AgregarGrupoFamiliar()"
                  >
                    Agregar al grupo</v-btn
                  >
                  <v-btn
                    v-if="bandera == 1"
                    class="mr-3"
                    color="primary"
                    @click="actualizar()"
                  >
                    Actualizar</v-btn
                  >
                  <v-btn v-if="bandera == 1" outlined @click="eliminar()">
                    Eliminar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="auto" class="pa-0">
              <v-divider :vertical="!$vuetify.breakpoint.smAndDown"></v-divider>
            </v-col>
            <!-- Seleccion de fecha, lista y validacion -->
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" v-if="itemsPacientes.length > 0">
                  <v-card>
                    <v-list
                      two-line
                      class="overflow-y-auto"
                      style="max-height: 200px"
                    >
                      <v-list-item-group active-class="blue--text">
                        <template v-for="(item, index) in itemsPacientes">
                          <v-list-item :key="item.nombre">
                            <v-list-item-content>
                              <v-list-item-title
                                ># Paciente:
                                {{ item.nombre }}</v-list-item-title
                              >

                              <v-list-item-subtitle class="text--primary"
                                >Apellido:
                                {{ item.apellido }}</v-list-item-subtitle
                              >
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn depressed @click="verPaciente(item)">
                                Editar
                                <v-icon
                                  color="blue"
                                  class="fa-solid fa-pencil"
                                  right
                                >
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>

                          <v-divider
                            v-if="index < itemsPacientes.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col>
                  <v-sheet width="100%" height="auto">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="12">
                        <p class="text-center title">
                          FECHA DE CITA EN EMBAJADA
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="fecha_consular"
                          label="Fecha"
                          type="date"
                          outlined
                          dense
                          :disabled="flag_fecha_consular"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="text-center title">
                          FECHAS DISPONIBLES
                          <v-tooltip bottom v-if="flag_no_cupos">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on"
                                >fa-solid fa-circle-info</v-icon
                              >
                            </template>
                            <span
                              >No exisen cupos disponibles desde su fecha
                              consular</span
                            >
                          </v-tooltip>
                        </p>
                      </v-col>
                      <v-col cols="auto" class="mx-auto">
                        <v-date-picker
                          v-model="fecha_cita"
                          :allowed-dates="allowedDates"
                          elevation="2"
                          :min="new Date().toISOString().substr(0, 10)"
                          full-width
                          :picker-date.sync="mes_a_mostrar"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12">
                  <div id="g-recaptcha"></div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    dark
                    @click="validarCaptcha()"
                    :loading="loading"
                    >Registrar cita</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog_cita_registrada" width="800" persistent>
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="mx-auto">
            Su cita ha sido registrada
            <v-icon large color="green"> fa-solid fa-circle-check </v-icon>
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <p class="text-justify red--text text--lighten-1 subtitle-1">
            Estimado solicitante: usted recibirá una llamada de su médico
            asignado dentro de las próximas 72 horas hábiles, por favor estar
            pendiente a la llamada, de lo contrario se perderá la cita.
          </p>
          <p class="text-justify red--text text--lighten-1 subtitle-1">
            Nota: Estará recibiendo un correo como comprobante de su cita, de no
            visualizar el correo en su *Bandeja de entrada* favor revisar en
            *Correo no deseado o Spam* y marcarlo como *Correo no deseado o
            Spam*.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" x-large @click="irInicio()">Continuar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TUTORIAL PARA USO DE SISTEMA -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title
          class="text-h6 font-weight-regular font-weight-regular justify-space-between"
          ><span>Tutorial para el registro de citas</span>
          <v-avatar
            color="primary lighten-1"
            class="subheading white--text"
            size="24"
          >
            <span>e1</span>
          </v-avatar>
        </v-card-title>
        <v-window v-model="e1">
          <v-window-item v-for="(item, i) in steps" :key="i" :value="item.id">
            <v-card-text>
              <span class="text-h6">{{ item.descripcion }}</span>
              <v-tooltip right v-if="item.tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar class="text-h6" v-bind="attrs" v-on="on"
                    ><v-icon> fa-solid fa-circle-info</v-icon></v-avatar
                  >
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
              <div class="borderStyle">
                <v-img
                  :src="item.img"
                  width="500"
                  max-height="350"
                  contain
                  @click="previewImg(item.img)"
                ></v-img>
              </div>
            </v-card-text>
          </v-window-item>
        </v-window>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="auto" align-self="center">
              <v-btn :disabled="e1 === 1" text outlined block @click="e1--">
                Atras
              </v-btn>
            </v-col>
            <v-col cols="12" md="auto" align-self="center">
              <v-btn text outlined block @click="cerrarDialog()">
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="12" md="auto" align-self="center">
              <v-checkbox
                v-model="c_message1"
                label="No volver a mostrar"
                @click="guardarNoVolverMostrar()"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="auto" align-self="center">
              <v-btn color="primary" depressed block @click="cerrarTutorial()">
                Siguiente
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VISTA PREVIA DE IMAGENES TUTORIAL -->
    <v-dialog v-model="dialog_preview_img" max-width="1000">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-img :src="img_preview" max-height="500" contain></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="dialog_preview_img = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" width="500">
      <v-card>
        <v-card-title>ATENCION!</v-card-title>
        <v-card-text class="sub-title-1 font-weight-medium text-justify">
          ESTIMADO SOLICITANTE, SI USTED YA SE HA REALIZADO ANTERIORMENTE
          EXÁMENES MÉDICOS, DEBE REPROGRAMAR CITA CON EL MISMO MEDICO QUE LE
          REALIZÓ DICHOS EXÁMENES MÉDICOS LLAMANDO POR TELÉFONO DIRECTAMENTE A
          LA OFICINA DEL MÉDICO. <br />
          <br />
          SI EL MÉDICO YA NO SE ENCUENTRA ACTIVO SEGÚN LISTADO PROPORCIONADO POR
          LA EMBAJADA, PUEDE CONTINUAR PROGRAMANDO SU CITA UTILIZANDO ESTA
          PLATAFORMA.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog2 = false">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ASIGNAR CITA AUTOMATICA -->
    <v-dialog v-model="dialog_cita_auto" width="600">
      <v-card>
        <v-card-title>IMPORTANTE!</v-card-title>
        <v-card-text class="subtitle-2">
          Si su cita en embajada es en menos de 7 días a partir de ahora, el
          sistema le asignará una cita para examen médico en los siguientes
          siete días hábiles posterior a su cita en embajada, pero al no tener
          examen médico NO PODRA PRESENTARSE A SU CITA EN EMBAJADA Y DEBE LLAMAR
          AL CALL CENTER DE EMBAJADA PARA REPROGRAMAR SU CITA CONSULAR.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog_cita_auto = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay v-model="overlay">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets6.lottiefiles.com/packages/lf20_uwR49r.json"
        style="width: 200px"
      >
      </lottie-player>
    </v-overlay>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { loadScript } from "vue-plugin-load-script";
import router from "@/router";
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    num_personas: 2,
    //datos medico
    nombre_medico: "",
    correo_medico: "",
    //datos del paciente
    checkbox_p92: false,
    id: 0,
    nombre: null,
    apellido: null,
    pasaporte: null,
    n_caso: null,
    telefono_n1: "",
    telefono_n2: "",
    telefono_n3: "",
    fecha_nacimiento: null,
    direccion: null,
    correo: null,
    checkboxUID: false,
    uid: "",
    itemsPacientes: [],
    ignoreCount: ["SV"],
    onlyCount: ["SV"],
    success_telefono_1: "",
    error_telefono_1: "",
    success_telefono_2: "",
    error_telefono_2: "",
    fecha_consular: "",
    items_tipo_visas: [],
    tipo_visa: 0,
    desc_visa: "",
    flag_uid: false, //null
    flag_fecha_consular: null,
    //seleccion de fecha
    fecha_cita: "",
    fechas_validas: [],
    //otras variables
    rules: [(v) => !!v || "Este campo es obligatorio"],
    requeridoCorreo: [
      (v) => !!v || "Correo es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Formato de correo es invalido",
    ],
    rules_numero: [(v) => (v && v >= 2) || "El número no debe ser menor a 2"],
    menu: false,
    activePicker: null,
    validForm: false,
    contador: 1,
    bandera: 0,
    loading: false,
    overlay: false,
    dialog: false,
    dialog2: false,
    e1: 1,
    steps: [
      {
        id: 1,
        title: "2",
        descripcion: "Completar cada uno de los campos del formulario",
        tooltip: "",
        img: require("@/assets/paso2.png"),
        width: 400,
      },
      {
        id: 2,
        title: "3",
        descripcion:
          "Seleccionar una fecha disponible resaltada, asegúrese de que sea aproximadamente 15 días antes de su cita consular",
        tooltip: "",
        img: require("@/assets/paso3.png"),
        width: 300,
      },
      {
        id: 3,
        title: "4",
        descripcion:
          "Una vez ingreso los datos de cada campo y seleccionara la fecha de su cita, se le habilitara un botón llamado 'REGISTRAR CITA' abajo del calendario.",
        tooltip: "",
        img: require("@/assets/paso4.png"),
        width: 350,
      },
      {
        id: 4,
        title: "5",
        descripcion:
          "Luego de dar en el botón 'REGISTRAR CITA' se le pedirá que valide la seguridad dando clic al cuadrado blanco 'No soy un robot'.",
        tooltip: "",
        img: require("@/assets/paso5.png"),
        width: 300,
      },
    ],
    itemsbreadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        href: "/",
      },
      {
        text: "Registro de cita grupal",
        disabled: true,
        href: "",
      },
    ],
    indice: 0,
    c_message1: false,
    dialog_preview_img: false,
    img_preview: "",
    htmlContent: "",
    dialog_cita_registrada: false,
    cita_vencida: false,
    mes_a_mostrar: "",
    dialog_cita_auto: false,
    flag_no_cupos: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    esta_logueado() {
      if (this.$store.state.usuario) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.listarTodosCupos();
    this.inicializarCaptcha();
    this.verNoVolverMostrar();
    this.listarTipoVisas();
  },
  methods: {
    validarCaposUID() {
      //TRUE -> NO REQUERIDO
      //FALSE -> REQUERIDO
      if (this.tipo_visa == 0) {
        return true;
      }

      if (this.flag_uid == false) {
        return false;
      } else {
        return true;
      }
    },
    validarCampo() {
      /*if(this.tipo_visa != "") {
        return false;
      } else {
        return true;
      }*/
      ////////////////////////////////////////
      //TRUE -> NO REQUERIDO
      //FALSE -> REQUERIDO
      if (this.tipo_visa == 0) {
        return true;
      }

      /*if (this.flag_uid == false) {
        if (this.uid.length < 8) {
          return true;
        } else if (this.uid.length == 8) {
          return false;
        }
      }

      if (this.flag_uid == true) {
        return false;
      }*/
      //////////////////////////////////////////
    },
    listarTipoVisas() {
      axios.get("api/TipoVisas/Listar").then((response) => {
        //console.log(response.data);
        this.items_tipo_visas = response.data;
      });
    },
    seleccionTipoVisa() {
      var uid = this.tipo_visa.cod_tipo_visa.split(" ")[0];
      var fecha_consular = this.tipo_visa.cod_tipo_visa.split(" ")[1];
      //var fecha_consular = this.tipo_visa.cod_tipo_visa;

      this.flag_uid = uid == "si-uid" ? false : true;
      this.flag_fecha_consular = fecha_consular == "si-fecha" ? false : true;
      this.checkboxUID = this.flag_uid ? false : null;
      this.uid = this.flag_uid ? "" : "";

      this.desc_visa = this.tipo_visa.descripcion;

      /*if (this.flag_fecha_consular == true) {
        this.listarCuposPorFechaConsularNoRequerido();
        this.fecha_consular = "";
      } else {
        this.fechas_validas = [];
        this.fecha_consular = "";
      }*/
    },
    inicializarCaptcha() {
      loadScript("https://www.google.com/recaptcha/api.js")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    listarCuposPorFechaConsular() {
      var date = new Date();
      var string_fecha_actual =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

      var date_fecha_actual = new Date(string_fecha_actual);
      var date_fecha_consular = new Date(this.fecha_consular);
      this.fecha_cita = "";

      //VALIDACION PARA EVITAR FECHAS CONSULARES ANTES DE 7 DIAS DE FECHA ACTUAL
      var dias = this.calculoDias(date_fecha_actual, date_fecha_consular);
      if (dias > 0 && dias < 7) {
        this.listarCuposFechaAutomatica();
        return;
      } else if (dias <= 0) {
        return;
      }

      this.fechas_validas = [];
      axios
        .get(
          "api/Cupos/ListarCuposDisponiblesPorFechaConsular/" +
            this.fecha_consular +
            "/" +
            string_fecha_actual
        )
        .then((response) => {
          //console.log(response.data);
          if (response.data.length == 0) {
            this.flag_no_cupos = true;
            return;
          }

          response.data.forEach((element) => {
            this.flag_no_cupos = false;
            this.mes_a_mostrar = response.data[0].fecha;
            //para mostrar las fechas de cupos disponibles en calendario
            if (this.$store.state.usuario) {
              if (this.$store.state.usuario.rol == "Administrador") {
                if (
                  element.id_consutorio ==
                  this.$store.state.usuario.id_consultorio
                ) {
                  this.fechas_validas.push({
                    id_consultorio: element.id_consutorio,
                    id_cupo: element.id_cupo,
                    id_medico: element.id_medico,
                    fecha: this.formatoFecha(element.fecha),
                    n_cupos: element.n_cupos,
                  });
                }
              }
            } else {
              this.fechas_validas.push({
                id_consutorio: element.id_consutorio,
                id_cupo: element.id_cupo,
                id_medico: element.id_medico,
                fecha: this.formatoFecha(element.fecha),
                n_cupos: element.n_cupos,
              });
            }
          });
        })
        .catch((error) => {
          this.alerta("Sucedio algúnu error", "error");
        });
    },
    listarCuposPorFechaConsularNoRequerido() {
      this.fechas_validas = [];
      this.fecha_cita = "";
      axios
        .get("api/Cupos/ListarCuposDisponiblesFechaConsularNoRquerido")
        .then((response) => {
          if (response.data.length == 0) {
            return;
          }
          this.mes_a_mostrar = response.data[0].fecha;
          //para mostrar las fechas de cupos disponibles en calendario
          response.data.forEach((element) => {
            if (this.$store.state.usuario) {
              if (
                element.id_consutorio ==
                this.$store.state.usuario.id_consultorio
              ) {
                this.id_medico = element.id_medico;
                this.fechas_validas.push({
                  id_consultorio: element.id_consutorio,
                  id_cupo: element.id_cupo,
                  id_medico: element.id_medico,
                  fecha: this.formatoFecha(element.fecha),
                });
              }
            } else {
              this.fechas_validas.push({
                id_consultorio: element.id_consutorio,
                id_cupo: element.id_cupo,
                id_medico: element.id_medico,
                fecha: this.formatoFecha(element.fecha),
              });
            }
          });
        });
    },
    listarCuposFechaAutomatica() {
      this.fechas_validas = [];
      this.fecha_cita = "";
      axios
        .get(
          "api/Cupos/ListarCuposAsignarFechaAutomatico/" + this.fecha_consular
        )
        .then((response) => {
          if (response.data.length == 0) {
            return;
          }

          this.dialog_cita_auto = true;
          this.mes_a_mostrar = response.data[0].fecha;
          this.fecha_cita = this.formatoFecha(response.data[0].fecha);
          this.fechas_validas.push({
            id_consutorio: response.data[0].id_consutorio,
            id_cupo: response.data[0].id_cupo,
            id_medico: response.data[0].id_medico,
            fecha: this.formatoFecha(this.fecha_cita),
          });
        });
    },
    listarTodosCupos() {
      axios.get("api/Cupos/ListarTodosCupos").then((response) => {
        response.data.forEach((element) => {
          this.fechas_validas.push({
            id_consultorio: element.id_consutorio,
            id_cupo: element.id_cupo,
            id_medico: element.id_medico,
            fecha: this.formatoFecha(element.fecha),
          });
        });
      });
    },
    calculoDias(startDate, endDate) {
      var elapsed, daysBeforeFirstSunday, daysAfterLastSunday;
      var ifThen = function (a, b, c) {
        return a == b ? c : a;
      };

      elapsed = endDate - startDate;
      elapsed /= 86400000;

      daysBeforeFirstSunday = (7 - startDate.getDay()) % 7;
      daysAfterLastSunday = endDate.getDay();

      elapsed -= daysBeforeFirstSunday + daysAfterLastSunday;
      elapsed = (elapsed / 7) * 5;
      elapsed +=
        ifThen(daysBeforeFirstSunday - 1, -1, 0) +
        ifThen(daysAfterLastSunday, 6, 5);

      return Math.ceil(elapsed);
    },
    validarCaptcha() {
      let me = this;

      if (this.tipo_visa == 0) {
        return this.alerta("Debe de seleccionar un tipo de visa", "info");
      }

      if (this.itemsPacientes.length <= 1) {
        this.alerta("Agregar al menos dos personas al grupo", "info");
        return;
      }

      if (this.itemsPacientes.length != this.num_personas) {
        this.alerta(
          "El número de personas no coincide con la cantidad de personas agregadas.",
          "info"
        );
        return;
      }

      if (this.flag_fecha_consular == false) {
        if (!this.fecha_consular) {
          this.alerta("Seleccione la fecha de cita en embajada", "info");
          return;
        }

        if (this.fecha_consular < this.fecha_cita) {
          this.alerta(
            "La fecha de cita en embaja no puede estar antes de su fecha de su cita médica",
            "info"
          );
          return;
        }

        if (this.fecha_consular == this.fecha_cita) {
          this.alerta(
            "La fecha de cita en embaja no puede ser la misma de su fecha de su cita médica",
            "info"
          );
        }
      }

      if (!this.fecha_cita) {
        this.alerta(
          "Favor de seleccionar una fecha de cita médica disponible",
          "info"
        );
        return;
      }

      this.loading = true;
      grecaptcha.render("g-recaptcha", {
        sitekey: "6LfXYVofAAAAAHYqoXLQ5In08Y7D69qJg-kIk5zd",
        callback: function () {
          me.verificarCitasAnteriores();
        },
      });
    },
    //funciones para lista previa/////////
    AgregarGrupoFamiliar() {
      /*if (this.flag_uid == false) {
        if (this.uid.length < 8) {
          return this.alerta("Debe de completar el campo UID", "info");
        }
      }*/

      if (!this.$refs.form.validate()) {
        return this.alerta(
          "Debe de completar los campos del aplicante",
          "info"
        );
      }

      if (!this.telefono_n1 || !this.telefono_n2) {
        return this.alerta(
          "Debe de agregar almenos dos números de teléfono",
          "info"
        );
      }

      if (this.telefono_n1 == "" || this.telefono_n2 == "") {
        this.alerta("Teléfono 1 y Teléfono 2 son sobligatorios", "info");
        return;
      }

      var tel1 = this.telefono_n1.replace(/\s+/g, "");
      var tel2 = this.telefono_n2.replace(/\s+/g, "");
      var tel3 = this.telefono_n3.replace(/\s+/g, "");

      if (tel1 != tel2 && tel1 != tel3 && tel2 != tel3) {
      } else {
        this.alerta("Los campos de teléfonos no pueden repetirse", "info");
        return;
      }

      if (
        this.nombre &&
        this.apellido &&
        this.pasaporte &&
        this.n_caso &&
        this.telefono_n1 &&
        this.telefono_n2 &&
        this.fecha_nacimiento &&
        this.direccion &&
        this.correo
      ) {
        this.itemsPacientes.push({
          id: this.contador,
          nombre: this.nombre,
          apellido: this.apellido,
          pasaporte: this.pasaporte,
          n_caso: this.n_caso,
          telefono_n1: this.telefono_n1,
          telefono_n2: this.telefono_n2,
          telefono_n3: this.telefono_n3,
          fecha_nacimiento: this.fecha_nacimiento,
          direccion: this.direccion,
          correo: this.correo.replace(/ /g, ""),
          uid: this.uid,
        });
        this.contador++;
        this.limpiarVariables();
        this.$refs.form.resetValidation();
      } else {
        this.checkboxUID = true;
        this.alerta("Debe de completar los campos obligatorios", "info");
        this.$refs.form.validate();
      }
    },
    verPaciente(item) {
      this.bandera = 1;
      this.checkboxUID = true;
      this.id = item.id;
      this.uid = item.uid;
      this.nombre = item.nombre;
      this.apellido = item.apellido;
      this.pasaporte = item.pasaporte;
      this.n_caso = item.n_caso;
      this.telefono_n1 = item.telefono_n1;
      this.telefono_n2 = item.telefono_n2;
      this.telefono_n3 = item.telefono_n3;
      this.fecha_nacimiento = item.fecha_nacimiento;
      this.direccion = item.direccion;
      this.correo = item.correo;
    },
    actualizar() {
      var tel1 = this.telefono_n1.replace(/\s+/g, "");
      var tel2 = this.telefono_n2.replace(/\s+/g, "");
      var tel3 = this.telefono_n3.replace(/\s+/g, "");

      if (tel1 != tel2 && tel1 != tel3 && tel2 != tel3) {
      } else {
        this.alerta("Los campos de teléfonos no pueden repetirse", "info");
        return;
      }

      if (
        this.nombre &&
        this.apellido &&
        this.pasaporte &&
        this.n_caso &&
        this.telefono_n1 &&
        this.telefono_n2 &&
        this.fecha_nacimiento &&
        this.direccion &&
        this.correo
      ) {
        for (var i = 0; i < this.itemsPacientes.length; i++) {
          if (this.itemsPacientes[i].id == this.id) {
            this.itemsPacientes[i].nombre = this.nombre;
            this.itemsPacientes[i].apellido = this.apellido;
            this.itemsPacientes[i].pasaporte = this.pasaporte;
            this.itemsPacientes[i].n_caso = this.n_caso;
            this.itemsPacientes[i].telefono_n1 = this.telefono_n1;
            this.itemsPacientes[i].telefono_n2 = this.telefono_n2;
            this.itemsPacientes[i].telefono_n3 = this.telefono_n3;
            this.itemsPacientes[i].fecha_nacimiento = this.fecha_nacimiento;
            this.itemsPacientes[i].direccion = this.direccion;
            this.itemsPacientes[i].correo = this.correo;
            this.itemsPacientes[i].uid = this.uid;
            this.bandera = 0;
            this.limpiarVariables();
            this.alerta("Se actualizaron los datos con exito", "success");
            break;
          }
        }
      } else {
        this.alerta("Debe de completar los campos obligatorios", "info");
        this.$refs.form.validate();
      }
    },
    eliminar() {
      for (var i = 0; i < this.itemsPacientes.length; i++) {
        if (this.itemsPacientes[i].id == this.id) {
          this.itemsPacientes.splice(i, 1);
          this.bandera = 0;
          this.limpiarVariables();
          this.alerta("Se ha eliminado con exito", "success");
          break;
        }
      }
    },
    /////////////////////////////////////
    verificarCitasAnteriores() {
      //devolvera una cita si el estado_finalizado esta en uno
      //de ser asi no se podra asignar una cita
      let flag = false;
      var hoy = this.formatoFecha(new Date());
      var flag_paciente = "";
      var cont = 0;
      var uid_vacio = false;

      if (this.uid == "") {
        uid_vacio = true;
      }

      this.itemsPacientes.forEach((element) => {
        axios
          .post("api/Pacientes/VerificarCitasAnteriores", {
            uid: element.uid,
            pasaporte: element.pasaporte,
            es_visa_p92: uid_vacio,
          })
          .then((response) => {
            cont++;
            response.data.forEach((element) => {
              var formato_fecha = this.formatoFecha(element.fecha_cita);
              if (new Date(formato_fecha) >= new Date(hoy)) {
                flag = true;
                flag_paciente = "Pasaporte: " + element.pasaporte;
                return;
              }
            });

            if (flag) {
              this.alerta(
                "Ya posee una cita pendiente el aplicante con los datos: " +
                  flag_paciente,
                "info"
              );
              grecaptcha.reset();
              return;
            }

            if (cont == this.itemsPacientes.length) {
              this.asignarMedico();
            }
          });
      });
    },
    asignarMedico() {
      var sin_cupos = true;
      var respuesta = grecaptcha.getResponse();
      var id_medico = 0;

      if (respuesta.length > 0) {
        axios
          .get(
            "api/Cupos/ListarCuposDisponiblesUnaFecha/" +
              this.formatoFecha(this.fecha_cita)
          )
          .then(async (response) => {
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].n_cupos >= this.itemsPacientes.length) {
                this.nombre_medico = response.data[i].nombre_medico;
                this.correo_medico = response.data[i].correo_medico;
                if (this.cita_vencida) {
                  id_medico = await (
                    await this.asignarMedicoManual()
                  ).data[0].id_medico;
                } else {
                  id_medico = response.data[i].id_medico;
                }

                this.guardarCita(id_medico, response.data[i].id_cupo);
                sin_cupos = false;
                break;
              }
            }
            if (sin_cupos) {
              this.alerta(
                "No hay cupos suficientes para esta fecha, favor de seleccionar otra fecha",
                "info"
              );
              grecaptcha.reset();
            }
          });
      }
    },
    async asignarMedicoManual() {
      return await axios.get(
        "api/Medicos/ListarxConsultorio/" +
          this.$store.state.usuario.id_consultorio
      );
    },
    guardarCita(id_medico, id_cupo) {
      let cont = 0;
      let flag = false;
      var i = new Date();
      var fecha =
        i.getFullYear() +
        "-" +
        (i.getMonth() + 1) +
        "-" +
        i.getDate() +
        " " +
        i.getHours() +
        ":" +
        i.getMinutes() +
        ":" +
        i.getSeconds();

      var cita = [];

      cita.push({
        id_medico: id_medico,
        fecha_creacion: fecha,
        fecha_cita: this.fecha_cita,
        id_cupo: id_cupo,
        es_grupal_individual: true,
        cita_vencida: this.cita_vencida,
        fecha_consular: this.fecha_consular,
        id_tipo_visa: this.tipo_visa.id_tipo_visa,
      });

      this.overlay = true;
      this.itemsPacientes.forEach((element) => {
        //console.log(element);
        //ver si ya termino de iterar los pacientes
        cont++;
        if (this.itemsPacientes.length == cont) {
          flag = true;
        }

        //primero se registra al paciente y automaticamente se registrara la cita
        axios
          .post("api/Pacientes/Crear", {
            nombre: element.nombre,
            apellido: element.apellido,
            pasaporte: element.pasaporte,
            n_caso: element.n_caso,
            fecha_nacimiento: element.fecha_nacimiento,
            telefono_n1: element.telefono_n1,
            telefono_n2: element.telefono_n2,
            telefono_n3: element.telefono_n3,
            direccion: element.direccion,
            correo: element.correo,
            uid: element.uid,
            citas: cita,
          })
          .then(() => {
            //
          })
          .catch((error) => {
            this.overlay = false;
            this.alerta("Sucedio algún error", "error");
          });
      });
      //va a ser true cuando termino de iterar la creacion de citas y pacientes
      if (flag) {
        this.actualizarCuposCitas(id_cupo);
      }
    },
    ////////////////////////////////////
    actualizarCuposCitas(id_cupo) {
      Promise.all([
        this.quitarCupo(id_cupo, this.itemsPacientes.length),
        this.indiceCitaRegistrada(id_cupo, this.itemsPacientes.length),
      ]).then(() => {
        this.overlay = false;
        this.alerta("Cita registrada con exito", "success");

        if (!this.cita_vencida) {
          this.enviarCorreoMedico();
          this.enviarCorreoPaciente();
        } else {
          this.dialog_cita_registrada = true;
          this.loading = false;
          grecaptcha.reset();
        }
      });
    },
    quitarCupo(id_cupo, indice) {
      //QUITA UN CUPO POR CITA REGISTRADA
      return axios.put("api/Cupos/QuitarCupo/" + id_cupo + "/" + indice);
    },
    indiceCitaRegistrada(id_cupo, indice) {
      //ESTE METODO GUARDA UN NUMERO POR CADA CITA REGISTRADA
      return axios.put("api/Cupos/CitasRegistradas/" + id_cupo + "/" + indice);
    },
    enviarCorreos() {
      Promise.all([this.enviarCorreoPaciente(), this.enviarCorreoMedico()]);
    },
    enviarCorreoMedico() {
      let header =
        "<html><body><h2>NOTIFICACIÓN DE CITA(S) PROGRAMADA(S)</h2><table border='1'cellspacing='0' cellpadding='5'><tr><th>Nombre</th><th>Pasaporte</th><th>Numero de caso</th><th>Teléfonos</th><th>Dirección</th><th>Fecha de nacimiento</th><th>Correo</th><th>Fecha de la cita</th><th>Tipo visa</th><th>Fecha consular</th></tr>";
      let footer = "</table></body></html>";

      this.itemsPacientes.forEach((element) => {
        if (this.htmlContent == "") {
          this.htmlContent =
            "<tr><td>" +
            element.nombre +
            " " +
            element.apellido +
            "</td><td>" +
            element.pasaporte +
            "</td><td>" +
            element.n_caso +
            "</td><td>" +
            element.telefono_n1 +
            " ," +
            element.telefono_n2 +
            " ," +
            element.telefono_n3 +
            "</td><td>" +
            element.direccion +
            "</td><td>" +
            element.fecha_nacimiento +
            "</td><td>" +
            element.correo +
            "</td><td>" +
            this.fecha_cita +
            "</td><td>" +
            this.tipo_visa.nombre +
            "</td><td>" +
            this.fecha_consular +
            "</td></tr> ";
        } else {
          this.htmlContent =
            this.htmlContent +
            "<tr><td>" +
            element.nombre +
            " " +
            element.apellido +
            "</td><td>" +
            element.pasaporte +
            "</td><td>" +
            element.n_caso +
            "</td><td>" +
            element.telefono_n1 +
            " ," +
            element.telefono_n2 +
            " ," +
            element.telefono_n3 +
            "</td><td>" +
            element.direccion +
            "</td><td>" +
            element.fecha_nacimiento +
            "</td><td>" +
            element.correo +
            "</td><td>" +
            this.fecha_cita +
            "</td><td>" +
            this.tipo_visa.nombre +
            "</td><td>" +
            this.fecha_consular +
            "</td></tr>";
        }
      });
      this.htmlContent = header + this.htmlContent + footer;

      axios
        .post("api/Citas/EnviarCorreo", {
          cod_mail: 2,
          correo_paciente: "",
          nombre_paciente: "",
          correo_medico: this.correo_medico,
          nombre_medico: this.nombre_medico,
          plantilla: this.htmlContent,
        })
        .then(() => {
          //
        });
    },
    enviarCorreoPaciente() {
      this.itemsPacientes.forEach((element) => {
        axios
          .post("api/Citas/EnviarCorreo", {
            cod_mail: 1,
            correo_paciente: element.correo,
            nombre_paciente: element.nombre + " " + element.apellido,
            correo_medico: "",
            nombre_medico: this.nombre_medico,
            plantilla: "",
          })
          .then(() => {
            //
          });
      });
      this.dialog_cita_registrada = true;
      this.loading = false;
      grecaptcha.reset();
    },
    ////////////////////////////////////
    validarTelefono1(numero, item) {
      this.success_telefono_1 = "";
      this.error_telefono_1 = "";

      if (item.regionCode == "SV") {
        this.error_telefono_1 = "Este teléfono no debe ser de El Salvador";
        return;
      }

      if (item.isValid) {
        this.success_telefono_1 = "Formato correcto";
      } else {
        this.error_telefono_1 = "Formato incorrecto";
      }
    },
    validarTelefono2(numero, item) {
      this.success_telefono_2 = "";
      this.error_telefono_2 = "";

      if (item.regionCode != "SV") {
        this.error_telefono_2 = "Este teléfono debe ser de El Salvador";
        return;
      }

      if (item.isValid) {
        this.success_telefono_2 = "Formato correcto";
      } else {
        this.error_telefono_2 = "Formato incorrecto";
      }
    },
    limpiarVariables() {
      //this.$refs.form.reset();
      this.nombre = "";
      this.apellido = "";
      this.pasaporte = "";
      this.n_caso = "";
      this.telefono_n1 = "";
      this.telefono_n2 = "";
      this.telefono_n3 = "";
      this.fecha_nacimiento = "";
      this.direccion = "";
      this.correo = "";
      this.uid = "";
    },
    alerta(texto, tipo_alerta) {
      this.$swal({
        toast: true,
        position: "top-end",
        title: texto,
        icon: tipo_alerta,
        showConfirmButton: false,
        timer: 8000,
        backdrop: false,
      });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DD");
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    allowedDates(val) {
      var index = this.fechas_validas.findIndex((object) => {
        return object.fecha == val;
      });
      if (index != -1) {
        return true;
      } else {
        return false;
      }
    },
    nextStep(n) {
      if (n === this.steps.length) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    startTour() {
      this.dialog = true;
    },
    verNoVolverMostrar() {
      if (localStorage.getItem("c_message_Group1")) {
        //this.dialog2 = true;
      } else {
        this.dialog = true;
      }
    },
    guardarNoVolverMostrar() {
      if (this.c_message1) {
        localStorage.setItem("c_message_Group1", "message_Group1");
      } else {
        localStorage.removeItem("c_message_Group1");
      }
    },
    cerrarDialog() {
      this.dialog = false;
      //this.dialog2 = true;
    },
    cerrarTutorial() {
      this.e1++;
      if (this.e1 > this.steps.length) {
        this.dialog = false;
        //this.dialog2 = true;
      }
    },
    previewImg(img) {
      this.img_preview = img;
      this.dialog_preview_img = true;
    },
    irInicio() {
      router.replace({ name: "bienvenida", params: {} }).catch((error) => {});
    },
  },
};
</script>

<style>
.borderStyle {
  border: solid rgb(179, 170, 170) 1px;
  padding: 5px;
}
</style>